'use client';

import { LinearProgress } from '@repo/ui';
import type { CourseType } from '@/store/static/course/courseStore';
import { useInteractiveCourseStore } from '@/store/mutable-data/course/CourseStoreContext';
import { getCourseProgress } from '@/utils/course-utils';

export default function CourseCardProgress({
    course,
    simple,
    myAvailable,
    userProfile,
}: {
    course: CourseType;
    simple: boolean;
    myAvailable: boolean;
    userProfile: boolean;
}) {
    const { courseUserAccess } = useInteractiveCourseStore();
    const { progress } = getCourseProgress(course, courseUserAccess.data, true);

    if (simple || (!myAvailable && !userProfile)) {
        return null;
    }

    return (
        <div style={{ margin: '1rem 0' }}>
            <LinearProgress style={{ marginBottom: '1rem' }} value={progress} />
            {progress.toFixed(0)}% complete
        </div>
    );
}
