import { Typography, Ribbon, FeaturedImage, Link, CardWrapper, Grid, Box } from "@repo/ui"
import Image from "next/image"
import CourseReviewCounts from "../CourseReviewCounts"
import CourseCardProgress from "./CourseCardProgress"

const CourseCard = ({
  course,
  myAvailable = false,
  simple = false,
  userProfile = undefined,
  big = false,
  lazyLoad = true,
  featured = false,
}) => {
  // const isFreeCourse = +course.coursePricing?.discountedPrice === 0
  // const { data: promoCode } = useAppSelector(state => state.courseData.promoCode)
  const promoCode = {}

  const coursePrice = { ...course.coursePricing }
  if (promoCode?.id) {
    const discount = (coursePrice.price / 100) * Number(promoCode.discountPercentage)
    coursePrice.discountedPrice = Number((coursePrice.price - discount).toFixed(2))
    coursePrice.discount = promoCode.discountPercentage
  }

  return (
    <Grid item xs={12} sm={6} md={6} lg={big ? 6 : 4}>
      <Link
        to={myAvailable ? `/courses/${course.urlSlug}/lessons/start` : `/courses/${course.urlSlug}`}
        style={{ position: "relative" }}
      >
        <CardWrapper
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          id={`course-selector-${course.id}`}
        >
          {/* {course.draft && <Ribbon>Soon</Ribbon>} */}
          {simple && (
            <>
              {/* {course.draft ? <Ribbon>Soon</Ribbon> : false && <Ribbon highlighted>Popular</Ribbon>} */}
              {course.draft ? <Ribbon>Soon</Ribbon> : false}
            </>
          )}

          <FeaturedImage
            Image={Image}
            width={featured ? 560 : undefined}
            height={featured ? 420 : undefined}
            src={course.coursePreviewVideo?.previewImage}
            lazyLoad={lazyLoad}
            alt={`${course.title} Course`}
          />

          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
            padding="1rem"
          >
            <Typography variant="h3" fontSize="1.5rem !important" margin="0 0 1rem 0">
              {course.title}
            </Typography>

            {course.statistics && <CourseReviewCounts statistics={course.statistics} simple />}

            <CourseCardProgress
              course={course}
              simple={simple}
              myAvailable={myAvailable}
              userProfile={userProfile}
            />
          </Box>
        </CardWrapper>
      </Link>
    </Grid>
  )
}

export default CourseCard
