import classNames from "classnames"
import { Grid , RatingStars } from "@repo/ui"
import styles from "./CourseReviewCounts.module.scss"

const CourseReviewCounts = ({ statistics = {}, simple }) => {
  if (!statistics || statistics.reviewsCount === 0) {
    statistics = {
      ...(statistics || {}),
      reviewsCount: statistics?.reviewsCount || 0,
      reviewsRatingAvg: statistics?.reviewsRatingAvg || 5,
      studentsCount: statistics?.studentsCount || 0,
    }
  }

  const rating = (Number(statistics.reviewsRatingAvg))?.toFixed(1)

  const containerClass = classNames(`${styles["rating-preview"]} rating-preview`, {
    [styles["rating-preview-simple"]]: simple,
  })

  return (
    <Grid container className={containerClass}>
      <Grid item className={styles["rating-stars"]}>
        <div className={styles["rating-stars-number"]}>{rating}+</div>
        {statistics.reviewsRatingAvg && <RatingStars value={statistics.reviewsRatingAvg} />}

        <div className={styles["rating-stars-count"]}>{statistics.reviewsCount || 0} reviews</div>
      </Grid>

      {!simple && (
        <Grid item className={styles["rating-stars-students-count"]} display="flex" alignItems="center">
          {statistics.studentsCount} students
        </Grid>
      )}
    </Grid>
  )
}

export default CourseReviewCounts
