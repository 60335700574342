'use client';

import { Button, Link, Box, Grid, CardCarousel } from '@repo/ui';
import Image from 'next/image';
import type { CourseType } from '@/store/static/course/courseStore';
import CourseCard from '@/components/atoms/CourseCard';
import CourseRenderer from '../CourseCardRenderer';

const CourseCardGrid = ({
    allCourses,
    simple = false,
    big = false,
}: {
    allCourses: CourseType[];
    simple?: boolean;
    big?: boolean;
}) => {
    if (!simple) {
        return (
            <Box margin="2rem 0">
                <CardCarousel
                    Image={Image}
                    itemName="Course"
                    items={allCourses}
                    title="Popular Courses"
                    CustomRenderer={CourseRenderer}
                />

                <Box
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '2rem 0 0 0',
                    }}
                >
                    <Link to="/catalog">
                        <Button>Explore all courses</Button>
                    </Link>
                </Box>
            </Box>
        );
    }

    return (
        <Grid container spacing={4}>
            {allCourses.map((course, index) => {
                // noLazy first 6, over the fold (to prefetch them)
                return (
                    <CourseCard
                        key={course.id}
                        course={course}
                        simple={simple}
                        big={big}
                        lazyLoad={index > 6}
                    />
                );
            })}
        </Grid>
    );
};

export default CourseCardGrid;
